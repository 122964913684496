<template>
  <div
    id="handcontroller"
    class="wellness__handcontroller"
    :class="device.isAutoMoving ? 'red': null"
    @click="() => { if(device.isAutoMoving) device.stop() }"
  >
    <!-- Up/down btns -->
    <div
      v-if="!device.isAutoMoving"
      class="arrows__container"
    >
      <b-icon
        class="arrow"
        :class="{'arrow-disabled': maxLimitReached && !defaultLimits}"
        icon="triangle-fill"
        @mousedown="device.moveUp()"
        @mouseup="device.stop()"
        @mouseover="$emit('mouseoverUp')"
        @mouseleave="$emit('mouseleaveUp'), device.stop()"
      />
      <b-icon
        class="arrow"
        :class="{'arrow-disabled': minLimitReached && !defaultLimits}"
        icon="triangle-fill"
        rotate="180"
        @mousedown="device.moveDown()"
        @mouseup="device.stop()"
        @mouseover="$emit('mouseoverDown')"
        @mouseleave="$emit('mouseleaveDown'), device.stop()"
      />
    </div>

    <!-- Stop btn -->
    <div
      v-else
      class="arrows__container"
      @click="device.stop()"
    >
      <b-icon
        class="stop"
        icon="square-fill"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { BIcon, BIconTriangleFill, BIconSquareFill } from 'bootstrap-vue';

export default {
  components: {
    BIcon,
    BIconTriangleFill,
    BIconSquareFill,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['device', 'defaultLimits'],
  computed: {
    maxLimitReached() {
      const { position } = this.device;
      const { highLimit } = this.device;

      if (Math.abs(position - highLimit) <= 20) {
        return true;
      }
      return false;
    },
    minLimitReached() {
      const { position } = this.device;
      const { lowLimit } = this.device;

      if (Math.abs(position - lowLimit) <= 20) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style lang="scss">
@import '../style/controller.scss';
.wellness__handcontroller {
  // Style
  background-color: var(--primary-color);
  border-radius: 0.5rem 0.5rem 0 0;
  display: flex;
  // justify-content: center;
  align-items: center;
  justify-content: center;

  // Size
  height: 70px;
  padding: 2rem;
  min-width: 15rem;

  // Position
  margin-left: auto;
  margin-right: auto;

  box-shadow: 3px 3px 0px 0px rgba(0,0,0,0.05);

  &.red {
    background-color: var(--toast--error);
    &:hover{
      cursor: pointer;
    }
  }
}

.arrows__container {
  display: flex;
  font-size: 48px;
  .arrow {
    margin-left: 20px;
    margin-right: 20px;
    path {
      fill: white;
      &:hover{
      cursor: pointer;
      }
    }
  }
  .stop {
    path {
      fill: white;
    }
    &:hover{
      cursor: pointer;
    }
  }
  .arrow-disabled {
    path {
      fill: var(--color-resource-other);
      pointer-events: none;
    }
  }
}
</style>
