import { render, staticRenderFns } from "./tooltip.vue?vue&type=template&id=22bfcab6"
import script from "./tooltip.vue?vue&type=script&lang=js"
export * from "./tooltip.vue?vue&type=script&lang=js"
import style0 from "./tooltip.vue?vue&type=style&index=0&id=22bfcab6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./tooltip.vue?vue&type=custom&index=0&blockType=TooltipComponent&class=test&title=Test%20title&body=Lorem%20ipsum%20dolor%20sit%20amet%2C%20consectetur%20adipiscing%20elit.%20Fusce%20quis%20pharetra%20mi.%20Vestibulum%20ornare%20ligula%20in%20lorem%20volutpat%20sollicitudin.&%3Aleft=true"
if (typeof block0 === 'function') block0(component)

export default component.exports