/*
  Example use in view:
  <Card>
    <SettingsListItem
      title="Test text"
      :info-icon="true"
    >
      <LinkSlotItem
        route="/statistics"
        text="statistics"
      />
    </SettingsListItem>
  </Card>
*/

<template>
  <div
    class="list-item-wrapper"
    @click="$emit('click')"
  >
    <div class="list-item-icon-text-wrapper">
      <h6 class="normal-semi">
        {{ title }}
      </h6>
      <p v-if="subtext">
        {{ subtext }}
      </p>
      <div
        v-if="infoIcon"
        class="info-icon-container"
      >
        <Tooltip
          class="test"
          :title="title"
          :body="infoBody"
          :left="true"
        />
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import Tooltip from '@/components/tooltip/tooltip.vue';

export default {
  components: {
    Tooltip,
  },
  props: {
    title: {
      required: false,
      default: '',
      type: String,
    },
    subtext: {
      required: false,
      default: '',
      type: String,
    },
    infoIcon: {
      required: false,
      default: false,
      type: Boolean,
    },
    infoBody: {
      type: String,
      required: false,
      default: 'Lorem Ipsum',
    },
  },
};
</script>

<style lang="scss">
  .list-item-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    p{
      margin: auto 0;
    }
    .list-item-icon-text-wrapper {
      display: flex;
      flex-direction: row;
      gap: 1.125rem;
      align-items: baseline;
      .list-item-text-wrapper {
        display: flex;
        flex-direction: column;
      }
      h6 {
        margin: auto 0;
      }
    }
    .info-icon-container{
      margin: auto 0;
    }
  }
</style>
