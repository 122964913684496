/*
  Example use in view:
    <TooltipComponent
      class="test"
      title="Test title"
      body="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis pharetra mi. Vestibulum ornare ligula in lorem volutpat sollicitudin."
      :left="true"
    />
      left prop tells tooltip if it is going to popout on the left side of the icon or not.
*/
<template>
  <div class="tooltip-component-wrapper">
    <svg-icon
      :id="`${title}-info-icon`"
      src="/icons/information_circle.svg"
    />
    <BTooltip
      :target="`${title}-info-icon`"
      :placement="left ? 'bottomleft' : 'bottomright'"
      triggers="hover"
    >
      <H6 class="padding padding-small padding-bottom padding-top">
        {{ title }}
      </H6>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="body" />
    </BTooltip>
  </div>
</template>

<script>
import { BTooltip } from 'bootstrap-vue';

export default {
  components: {
    BTooltip,
  },
  props: {
    title: {
      required: true,
      default: '',
      type: String,
    },
    body: {
      required: false,
      default: '',
      type: String,
    },
    left: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      position: null,
    };
  },
  mounted() {
    const root = document.documentElement;
    if (this.left) {
      root.style.setProperty('--left', '1rem');
      root.style.setProperty('--move-left', '-0.6rem');
    } else {
      root.style.setProperty('--right', '1rem');
      root.style.setProperty('--move-left', '0.6rem');
    }
  },

};
</script>

<style lang="scss">
:root{
  --left: 0;
  --right: 0;
  --move-left: 0;
}
.tooltip {
  top: 0.8rem !important;
  left: var(--move-left) !important;
  .arrow::before{
    border-bottom-color: var(--resource-pastel-color);
    border-width: 0rem var(--right) 1rem var(--left);
  }

  .tooltip-inner {
    box-shadow: 0.2rem 0.2rem 0.5rem var(--background-kebab-color);
    border-radius: 0.5rem;
    background-color: var(--resource-pastel-color);
    color: var(--primary-font-color);
    max-width: 16rem;
    text-align: left;
    h6 {
      color: var(--primary-color);
      border-bottom: 0.1rem solid var(--primary-color);
      text-align: start;
    }
  }
}
.tooltip.b-tooltip {
  opacity: 1;
}
.tooltip-component-wrapper {
  .icon {
    fill: var(--primary-color);
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
</style>
