<template>
  <div class="height__bar--container">
    <!-- ICONS -->
    <div class="side-info--container">
      <!-- Standing and sitting icons -->
      <div class="relative">
        <div
          class="absolute"
          :style="{bottom: standIconPos}"
          @mouseup="safeControl ? device.stop() : () => {}"
          @mousedown="device.moveToPosition(standPos)"
        >
          <div
            v-if="standPos"
            class="side-info"
          >
            <div class="position_icon stand">
              <svg-icon
                src="icons/dash.svg"
                :class="isActivePosition('stand') ? 'position-icon active' : 'position-icon'"
              />
              <svg-icon
                src="icons/stand.svg"
                :class="isActivePosition('stand') ? 'position-icon active' : 'position-icon'"
              />
            </div>
          </div>
        </div>

        <div
          class="absolute"
          :style="{bottom: sitIconPos}"
          @mouseup="safeControl ? device.stop() : () => {}"
          @mousedown="device.moveToPosition(sitPos)"
        >
          <div
            v-if="sitPos"
            class="side-info"
          >
            <div class="position_icon sit">
              <svg-icon
                src="icons/dash.svg"
                :class="isActivePosition('sit') ? 'position-icon active' : 'position-icon'"
              />
              <svg-icon
                src="icons/sit.svg"
                :class="isActivePosition('sit') ? 'position-icon active' : 'position-icon'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Usable area-->
    <div
      class="white-space"
      :style="{bottom: lowLimitHeight, height: whiteSpace}"
    />

    <!-- Current Height (green bar) -->
    <div
      class="position__bar--value"
      :style="{ bottom: lowLimitHeight, height: fillHeight }"
    />

    <!-- Background -->
    <div class="position__bar--background" />
  </div>
</template>

<script>
import UserState from '@/singletons/user.state.singleton';

export default {
  props: {
    currentHeight: {
      type: Number,
      required: true,
    },
    lowLimit: {
      type: Number,
      required: false,
      default: 0,
    },
    highLimit: {
      type: Number,
      required: false,
      default: 0,
    },
    maxHeight: {
      type: Number,
      required: true,
    },
    sitPos: {
      type: Number,
      required: false,
      default: null,
    },
    standPos: {
      type: Number,
      required: false,
      default: null,
    },
    device: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      UserState,
    };
  },
  computed: {
    fillHeight() { return `${Math.min(Math.floor((this.currentHeight / this.maxHeight) * 100) - parseInt(this.lowLimitHeight, 10), 100)}%`; },
    whiteSpace() { return `${Math.min(Math.abs(parseInt(this.highLimitHeight, 10) - parseInt(this.lowLimitHeight, 10)), 100)}%`; },
    highLimitReminderHeight() { return `${100 - Math.floor((this.highLimit / this.maxHeight) * 100)}%`; },
    lowLimitHeight() { return `${Math.floor((this.lowLimit / this.maxHeight) * 100)}%`; },
    highLimitHeight() { return `${Math.floor((this.highLimit / this.maxHeight) * 100)}%`; },
    sitIconPos() { return `${Math.floor((this.sitPos / this.maxHeight) * 100)}%`; },
    standIconPos() { return `${Math.floor((this.standPos / this.maxHeight) * 100)}%`; },
    safeControl() { return !!UserState.profile.safecontrol; },
  },
  methods: {
    isActivePosition(state) {
      const compareState = state === 'sit' ? this.sitPos : this.standPos;
      const activeState = Math.abs(this.sitPos - this.currentHeight) < Math.abs(this.standPos - this.currentHeight) ? this.sitPos : this.standPos;
      return compareState === activeState;
    },
  },
};
</script>

<style lang="scss" >
$bar-height: 230px;
.height__bar--container{
  height: $bar-height;
  position: relative;
}

//BAR CSS
.position__bar--value {
  border-radius: 0.3rem;
  position: absolute;
  width: 2.6rem;
  bottom: 0;
  margin: 0px 1px;
  background-color: var(--primary-color);
  transition: height 0.1s linear 0s;
  z-index: 20;
}

.position__bar--background {
  width: 2.8rem;
  position: absolute;
  height: $bar-height + 3px;
  border-radius: 0.3rem;
  background-color: #BFBFBF;
  z-index: 0;
  top: -3px;
}
.white-space {
  background-color: white;
  position: absolute;
  width: 2.6rem;
  border-radius: 0.3rem;
  margin: 0px 1px;
  z-index: 10;
}

// ICON CSS
.side-info--container{
  height: $bar-height;
  position: absolute;
  .absolute{
    position: absolute;
    left: 50px;
    cursor: pointer;
    &:hover {
      svg {
        animation: 1s blink ease-in-out infinite;
      }
    }
  }
  .relative{
    position: absolute;
    height: $bar-height;
  }
}
.side-info{
  transform: translateY(35%);
  .position_icon{
    display: flex;
    justify-content: flex-start;
    fill: var(--secondary-font-color);
    gap: 0.1rem;
    .position-icon{
      border-radius: 100%;
      svg{
        height: 1.6rem;
      }
      &.active{
        svg{
          fill: var(--primary-color);
          transition: fill 0.5s ease;
        }
      }
    }
  }
}
  @keyframes blink {
    from, to {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
  }
</style>
