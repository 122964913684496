import UserState from '@/singletons/user.state.singleton';

/* mm to cm or in */
export const mmToUnitParsed = (_mm, offset = 0, _unit = null) => {
  const unit = _unit || UserState.profile.MeasurementSystem;
  const mm = offset + _mm;
  if (unit === 'METRIC') {
    return (mm / 10).toFixed(1);
  }
  return (mm / 25.4).toFixed(1);
};

/* in or cm to mm */
export const unitToMm = (value, offset = 0, _unit = null) => {
  let mm = 0;
  const unit = _unit || UserState.profile.MeasurementSystem;
  if (unit === 'METRIC') {
    mm = value * 10;
  } else {
    mm = value * 25.4;
  }
  return (mm - offset).toFixed(1);
};
