<template>
  <img
    class="image"
    :src="src"
  >
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
  },

};
</script>

<style lang="scss" scoped>
.image{
  margin-left: -25px;
  height: 230px;
  width: fit-content;
  bottom: 0;
}
</style>
